<template>
  <div v-if="ready">
    <p class="h6">Parrainage</p>
    <b-row>
      <b-col md="12">
        <b-form-input v-model="titre" @keyup="search()"></b-form-input>
        <b-list-group class="search-result" v-if="data.length > 0">      
          <b-list-group-item href="javascript:void(0)" v-for="(d,i) in data" :key="i" @click="setData(d)">{{ d.titre }}</b-list-group-item>      
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: "InputForm",
  data(){
    return {
      titre:null,
      ready:false,
      data:[]
    }
  },
  props: {
    value: {
      required: true
    },
    parrain: {
      required: true,
      default: false
    },    
  },
  watch:{   
    titre(){
      if(this.titre.length < 4){
        this.data = [];
      }
    } 
  },
  model: {
    prop: 'value',
    event: 'inputChange'
  },
  computed: {    
    valueLocal: {
      get() {
        return this.value ?? null;
      },
      set(value) {
        if(value === undefined){
          value = null;
        }
        this.$emit('inputChange', value)
      }
    }
  },
  methods: {    
    search(){
      if(this.titre.length < 4){
        return false;
      }
      var params = {
        filters:{titre: this.titre},
        sort:{key:"titre", value:"ASC"},
        limit:10,
        page:1
      }
      this.$api.ajax('/client/liste', params, res => {
        this.data = res.data;
      })
    },
    setData(d){
      this.valueLocal = d.id;
      this.titre = d.titre;   
      this.data = [];   
    }
  },
  mounted() { 
    if(this.parrain != false){
      this.titre = this.parrain.nom+" "+this.parrain.prenom;      
    }
    this.ready = true;
  }
}
</script>
<style lang="scss" scoped>
.search-result {
  position: absolute;
  width: 88%;
  z-index: 1;
}
</style>
